import PasswordValidator from 'password-validator';

// password validation
// min 8 characters one numeric digit one special character
// one uppercase
// one lowercase
// 8-32 characters long
// contain one digit
// contain one special character
const SPECIAL_CHARACTERS = `#$@!%&*?\\-:\\\\`;
const __SPECIAL_CHARACTERS = [
  ...SPECIAL_CHARACTERS.replace('\\', '').split(''),
  '\\'
].join(' ');

const passwordValidator = new PasswordValidator();
passwordValidator
  .is()
  .min(8)
  .is()
  .max(32)
  .has()
  .uppercase(1)
  .has()
  .lowercase(1)
  .has()
  .digits(1)
  .has()
  .symbols(1);

function validatePassword(password: string): boolean {
  const result: string[] = passwordValidator.validate(password, {
    list: true
  }) as string[];
  return result.length === 0;
}

const passValidations = [
  validateUpperCase,
  validateLowerCase,
  validateLength,
  validateDigit,
  validateSpecialCharacter
];

const passTitles = [
  [validateUpperCase, 'Password must contain at least one uppercase character'],
  [validateLowerCase, 'Password must contain at least one lowercase character'],
  [validateLength, 'Password must be between 8 - 32 characters'],
  [validateDigit, 'Password must contain a digit'],
  [
    validateSpecialCharacter,
    `Password must contain a special character. Special characters: ${__SPECIAL_CHARACTERS}`
  ]
] as const;

// const passTitles2 = {
//   min: 'Password must be between 8 - 32 characters',
//   max: 'Password must be between 8 - 32 characters',
//   uppercase: 'Password must contain at least one uppercase character',
//   lowercase: 'Password must contain at least one lowercase character',
//   digits:  'Password must contain at least one digit',
//   symbols: `Password must contain a special character. Special characters: ${__SPECIAL_CHARACTERS}`,
// } as const

const passMap = new Map(passTitles);

const gatherAllValidations = function (password: string) {
  const allValidations = passValidations.map(validation => {
    return {
      title: passMap.get(validation),
      result: validation(password) !== true ? false : true
    };
  });

  return allValidations;
};

const gatherValidations = function (password: string) {
  const failedValidations = passValidations.reduce<(boolean | Error)[]>(
    (acc, validation) => {
      const pass = validation(password);
      if (pass !== true) {
        acc.push(pass);
      }
      return acc;
    },
    []
  );

  return failedValidations;
};

function validateUpperCase(password: string): boolean | Error {
  if (password.match(/[A-Z]/) === null)
    return new Error('Password must contain at least one uppercase character');
  return true;
}
function validateLowerCase(password: string): boolean | Error {
  if (password.match(/[a-z]/) === null)
    return new Error('Password must contain at least one lowercase character');
  return true;
}
function validateLength(password: string): boolean | Error {
  if (password.length < 8 || password.length > 32)
    return new Error(
      'Password must be a length between 8 - 32 characters long'
    );
  return true;
}
function validateDigit(password: string): boolean | Error {
  if (password.match(/\d/) === null)
    return new Error('Password must contain a digit');
  return true;
}

function validateSpecialCharacter(password: string): boolean | Error {
  if (password.match(/[@$!%*?&]/) === null)
    return new Error('Password must contain at least one special character');
  return true;
}

// validate form submission to have all included values of interface PatientProfile2
// not sure why i can't import from patient-profile itself in this file. workaround.
// interface are only there during compile time, they are omitted in transpiled code..
// interface PatientProfile2 extends Document {
//   birthDate?: Moment;
//   sex?: Gender;
//   primaryLanguage?: string;
//   englishFluency?: EnglishFluency;
//   injuryDate?: Moment;
//   concussionDiagnosis?: boolean;
//   injuryMechanism?: InjuryMechanism;
//   impactLocation: ImpactLocation;
// }
// make sure all keys of interface are in the form submission
const validateProfileForm2 = function (form: any) {
  // required properties with values
  const patientProfileKeys = [
    'birthDate',
    'sex',
    'primaryLanguage',
    'englishFluency',
    'injuryDate',
    'concussionDiagnosis', // boolean here
    'injuryMechanism',
    'impactLocation'
  ];

  const n = patientProfileKeys.length;

  for (let i = 0; i < n; i++) {
    const requiredKey = patientProfileKeys[i];
    if (requiredKey === 'impactLocation') {
      console.log(form);
      return form.impactLocation.length > 0;
    }
    if (form[requiredKey] === null || form[requiredKey] === undefined) {
      console.log('requiredKey failed: ', requiredKey);
      return false;
    }
  }

  return true;
};

export {
  validatePassword,
  validateProfileForm2,
  gatherValidations,
  gatherAllValidations
};
