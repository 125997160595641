import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  SyncOutlined
} from '@ant-design/icons';
import { Flex, Tag, TagProps, Tooltip } from 'antd';
import clsx from 'clsx';
import { ErrorDisplay } from 'features/patient/assessment/questionnaire/ErrorDisplay';
import {
  createContext,
  ReactElement,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { resolveError } from 'utils/formatters/error/resolve-error';
import {
  DynamicOrStaticProperty,
  resolveDynamicOrStaticProperty
} from '../../../utils/dynamic-or-static-property';
import { InData, TableRow } from './user-list-component';

export const ActionTagErrorContext = createContext<{
  errors: { [actionId: string]: Error | null };
  setError: (id: string, error: Error | null) => void;
}>({ errors: {}, setError: () => !!0 });

export function ActionTagErrorContextProvider(props: { children: ReactNode }) {
  const [ctxState, setCtxState] = useState<Record<string, Error | null>>({});
  return (
    <ActionTagErrorContext.Provider
      {...props}
      value={{
        errors: ctxState,
        setError: (id, error) => {
          setCtxState(s => ({ ...s, [id]: error }));
        }
      }}
    />
  );
}

export function ActionTagErrorDisplay() {
  const ctx = useContext(ActionTagErrorContext);

  const errorGroups = Object.keys(ctx.errors);
  return (
    <div>
      <Flex vertical gap={'small'}>
        {errorGroups.map(errorGroup => {
          const error = ctx.errors[errorGroup];
          if (error === null) return;
          return (
            <ErrorDisplay
              key={errorGroup}
              title={`Failed to process action for ${errorGroup}`}
              description={''}
              error={error}
            />
          );
        })}
      </Flex>
    </div>
  );
}

export enum ActionState {
  NEUTRAL = 'NEUTRAL',
  PROCESSING = 'PROCESSING',
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS'
}

type ActionState_NotError =
  | ActionState.NEUTRAL
  | ActionState.PROCESSING
  | ActionState.SUCCESS;
type ActionTagState =
  | {
      state: ActionState_NotError;
      error: null;
    }
  | { state: ActionState.ERROR; error: Error };
export interface ActionTagRenderProps<T extends InData> {
  record: TableRow<T>;
  onClick: ActionTagSettings<T>['onClick'];
  name: string;
  disabled: boolean;
  tagStyles: Pick<TagProps, 'icon' | 'color'>;
}

export interface ActionTagSettings<T extends InData> {
  /**
   * Defaults to `true`
   */
  shouldShow?: boolean | ((a: TableRow<T>) => boolean);
  name: DynamicOrStaticProperty<string, TableRow<T>>;
  onClick: (a: TableRow<T>) => Promise<void | any> | void | any;
  toolTip?: DynamicOrStaticProperty<string, TableRow<T>>;
  initialState?: (a: TableRow<T>) => ActionState_NotError;
  render?: (props: ActionTagRenderProps<T>) => ReactElement;
  onSuccess?:
    | {
        /**
         * Block the user from triggering the action component again after a successful run
         *
         * If `initialState` returns a status other than success then the action can be triggered again after reloading the page
         */
        blockOnDone: true;
      }
    | {
        /**
         * Allow the user to triggering the action component again after a successful run
         */
        blockOnDone: false;
        /**
         * The time in milliseconds before the action component returns to a default state
         */
        timeout: number;
      };
}

export interface ActionTagProps<T extends InData> {
  record: TableRow<T>;
  action: ActionTagSettings<T>;
}

export function ActionTag<T extends InData>(props: ActionTagProps<T>) {
  const errorContext = useContext(ActionTagErrorContext);
  const [state, setState] = useState<ActionTagState>({
    state: ActionState.NEUTRAL,
    error: null
  });

  useEffect(() => {
    if (props.action.initialState) {
      setCurrentState(props.action.initialState(props.record));
    }
  }, [props.record, props.action]);

  function setCurrentState(newState: ActionState_NotError) {
    setState(s => ({ ...s, state: newState, error: null }));
  }

  async function doAction(recordOverride?: TableRow<T>) {
    try {
      setCurrentState(ActionState.PROCESSING);
      await props.action.onClick(recordOverride ?? props.record);
      setCurrentState(ActionState.SUCCESS);
      errorContext.setError(props.record.email, null);

      // Check if the action tag should block after a successful run
      if (props.action.onSuccess?.blockOnDone === false) {
        const timeout = props.action.onSuccess?.timeout;

        // Set the action tag to NEUTRAL after `timeout`
        setTimeout(() => {
          setCurrentState(ActionState.NEUTRAL);
        }, timeout);
      }
    } catch (e) {
      const error = resolveError(e);
      setState(s => ({ ...s, error, state: ActionState.ERROR }));
      errorContext.setError(props.record.email, error);
    }
  }

  const tagStyles = useMemo<TagProps>(() => {
    switch (state.state) {
      case ActionState.NEUTRAL:
        return { color: 'default' };
      case ActionState.PROCESSING:
        return { icon: <SyncOutlined spin />, color: 'gray' };
      case ActionState.ERROR:
        return { icon: <CloseCircleOutlined />, color: 'error' };
      case ActionState.SUCCESS:
        return { icon: <CheckCircleOutlined />, color: 'success' };
      default:
        return {};
    }
  }, [state]);
  const tooltipText = useMemo(
    () => resolveDynamicOrStaticProperty(props.action.toolTip, props.record),
    [props.action, props.record]
  );
  const name = useMemo(
    () => resolveDynamicOrStaticProperty(props.action.name, props.record),
    [props.action, props.record]
  );

  const isDisabled = [ActionState.SUCCESS, ActionState.PROCESSING].includes(
    state.state
  );

  return (
    <Tooltip title={tooltipText}>
      {props.action.render ? (
        <props.action.render
          record={props.record}
          onClick={data => {
            if (isDisabled) return;
            return doAction(data);
          }}
          name={name}
          disabled={isDisabled}
          tagStyles={tagStyles}
        />
      ) : (
        <Tag
          {...tagStyles}
          className={clsx('clickable', isDisabled ? 'disabled' : '')}
          style={{ padding: '0.1rem' }}
          onClick={() => {
            if (isDisabled) return;
            doAction();
          }}
        >
          {name}
        </Tag>
      )}
    </Tooltip>
  );
}
