import { Table } from 'antd';
import { adminQueryPatientAssessmentStatus } from 'api/firebase/firebase-api';
import { Text } from 'components/mvp-typography';
import { PrintableRootPage } from 'components/page/page-2';
import moment from 'moment';
import { useEffect, useState } from 'react';

/**
 * Copied from backend
 */
interface PatientInfo {
  patientId: string;
  displayName: string;
  email: string;
  signUpDate: moment.Moment;
  /**
   * Includes pending assessments
   */
  recentAssessmentDate: moment.Moment | null;
  /**
   * The last confirmed assesment
   */
  lastCompletedAssessment: moment.Moment | null;
}

type APIPatientInfo = PatientInfo & {
  lastCompletedAssessment: string | null;
  recentAssessmentDate: string | null;
  signUpDate: string;
};

export default function ClinicianPatientAssessments() {
  const [data, setData] = useState<{ data: PatientInfo[]; isLoading: boolean }>(
    { data: [], isLoading: true }
  );
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    async function setup() {
      try {
        const data = await adminQueryPatientAssessmentStatus();
        const dataArr = JSON.parse(data.data) as APIPatientInfo[];

        const fixedArr = dataArr.map(_ => ({
          ..._,
          lastCompletedAssessment:
            _.lastCompletedAssessment !== null
              ? moment(_.lastCompletedAssessment)
              : null,
          recentAssessmentDate:
            _.lastCompletedAssessment !== null
              ? moment(_.recentAssessmentDate)
              : null,
          signUpDate: moment(_.signUpDate)
        }));
        setData({ data: fixedArr, isLoading: false });
      } catch (e) {
        console.error(
          `Failed to fetch ${adminQueryPatientAssessmentStatus.name} API`
        );
        console.error(e);
        setError(e as Error);
      }
    }
    setup();
  }, []);

  return (
    <PrintableRootPage title="Patient Assessments">
      <Table
        loading={data.isLoading}
        dataSource={data.data}
        sortDirections={['descend', 'ascend']}
        columns={[
          {
            title: 'Name',
            dataIndex: 'displayName',
            sorter: (a, b) => a.displayName.localeCompare(b.displayName)
          },
          {
            title: 'Email',
            dataIndex: 'email',
            sorter: (a, b) => a.email.localeCompare(b.email)
          },
          {
            title: 'Sign up date',
            dataIndex: 'signUpDate',
            render: (val: moment.Moment) => val.format('YYYY-MM-DD'),
            sorter: (a, b) =>
              Number(a.signUpDate?.unix() ?? 0) -
              Number(b.signUpDate?.unix() ?? 0)
          },
          {
            title: 'Last completed assessment',
            dataIndex: 'lastCompletedAssessment',
            render: (val: moment.Moment | null) =>
              val === null ? val : val.format('YYYY-MM-DD'),
            sorter: (a, b) =>
              Number(a.lastCompletedAssessment?.unix() ?? 0) -
              Number(b.lastCompletedAssessment?.unix() ?? 0)
          },
          {
            title: 'Recent assessment date',
            dataIndex: 'recentAssessmentDate',
            render: (val: moment.Moment | null) =>
              val === null ? val : val.format('YYYY-MM-DD hh:mm:ss'),
            sorter: (a, b) =>
              Number(a.recentAssessmentDate?.unix() ?? 0) -
              Number(b.recentAssessmentDate?.unix() ?? 0)
          }
        ]}
      />

      {
        /**
         * Display an error message if the data fails to load
         */
        error && (
          <Text color={'red'}>
            An eror occured when loading data. {error?.message} If you think
            this is an error please wait a few moments and try again, or contact
            ConcussionRX.
          </Text>
        )
      }
    </PrintableRootPage>
  );
}
