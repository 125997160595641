import { Flex } from 'antd';
import { Link, Subtitle, Text, Title } from 'components/mvp-typography';
import { PatientInfoState } from 'components/patient-info/patient-info-slice-types';
import { getFirstName } from 'utils/string/normalise';

export function PatientHomeAssessmentBlocked(props: {
  patientInfo: PatientInfoState;
}) {
  if (props.patientInfo.isLoading) return null;

  const firstName = getFirstName(props.patientInfo.patient.displayName ?? '');
  return (
    <Flex
      style={{ height: '100%', justifyContent: 'center', textAlign: 'center' }}
    >
      <Flex
        style={{ width: '60%', alignItems: 'center', paddingTop: '20px' }}
        gap={60}
        vertical
      >
        <div>
          <Title
            text={`Let's get you better, ${firstName}!`}
            color={'#230056'}
          />
        </div>
        <div>
          <Title className="crx-typography h3" uppercase>
            ConcussionRx is an innovative clinician co-pilot that is here to
            make sure you get the best course of treatment for your concussion.
          </Title>
        </div>
        <div
          style={{
            background: 'white',
            padding: '1.2rem',
            borderRadius: 5,
            maxWidth: 650,
            color: 'black',
            fontWeight: 'bold'
          }}
        >
          <Text>
            If you wish to take the assessment at home ahead of your in-clinic
            appointment, please contact the clinic to activate your assessment.
          </Text>
          <br></br>
          <br></br>
          <Text>
            Otherwise, the assessment will be activated when you arrive to the
            clinic for your appointment.
          </Text>
        </div>
        <div>
          <Subtitle
            style={{ padding: '100px 0px 10px 0px' }}
            className="crx-typography button-big"
            color={'#A366FC'}
          >
            If you have any questions or concerns, please contact your clinic or
            visit our website{' '}
            <Link text={'here'} href={'https://concussionrx.com'} />
          </Subtitle>
        </div>
      </Flex>
    </Flex>
  );
}
