import { Alert, Skeleton } from 'antd';
import { auth } from 'api/firebase';
import { useAppSelector } from 'app/rootReducer';
import { Text, Title } from 'components/mvp-typography';
import { replace } from 'connected-react-router';
import { User } from 'features/auth/authSlice';
import { jwtDecode } from 'jwt-decode';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { resolveError } from 'utils/formatters/error/resolve-error';
import { useQuery } from 'utils/router/use-query';
import { z } from 'zod';

interface AcceptInviteProcessState {
  isLoading: boolean;
  error: Error | null;
  nextStep: null | 'set_password' | 'invite_accepted';
}

/**
 * Page for accepting invites
 */
export function AcceptInvite() {
  const query = useQuery();
  const [processState, setProcessState] = useState<AcceptInviteProcessState>({
    isLoading: true,
    error: null,
    nextStep: null
  });

  const { user, isUserDataLoading: isLoading } = useAppSelector(s => s.auth);

  const dispatch = useDispatch();

  useEffect(() => {
    async function process(user: User | null, isLoading: boolean) {
      if (isLoading) return;
      const invite = getInvitationDetails(query);

      if (invite.error) {
        throw invite.error;
      }

      if (user && user.uid !== invite.invitationDetails.sub) {
        throw new Error(
          'This invite is not for your user. Please log out and try again.'
        );
      }

      if (!user) {
        // Sign the user in
        await auth
          .signInWithCustomToken(invite.invitationDetails.auth.authToken)
          .catch(reason => {
            const error = resolveError(reason);
            error.message = `Failed to sign in. ${error.message}`;
            throw error;
          });
        return;
      }

      dispatch(
        replace({
          pathname: '/',
          search: query.toString()
        })
      );

      setProcessState(state => ({
        ...state,
        isLoading: false,
        nextStep: 'invite_accepted'
      }));
    }

    process(user, isLoading).catch(error => {
      setProcessState(state => ({
        ...state,
        error: resolveError(error),
        isLoading: false
      }));
    });
  }, [query, dispatch, user, isLoading]);

  /**
   * This page can only be loaded if there is an invite to be loaded
   */
  if (processState.error) {
    // TODO: Add a button to navigate back to the home page
    return (
      <div>
        <Alert
          type="error"
          message={processState.error.message}
          description={
            <Text>Please contact your clinic to request a new invitation</Text>
          }
          showIcon
        />
      </div>
    );
  }

  if (processState.nextStep === null || processState.isLoading || isLoading) {
    return (
      <div>
        <Title>Processing invite</Title>
        <Skeleton active />
      </div>
    );
  }

  if (processState.nextStep === 'set_password') {
    return (
      <Title>Please wait while you are redirected to set your password</Title>
    );
  }

  return (
    <div>
      <Alert
        type="success"
        showIcon
        message={`Invitation accepted - ${user}`}
        description={
          <Text>
            Click{' '}
            <Link to={'/'} replace>
              here
            </Link>{' '}
            to navigate to the rest of the app
          </Text>
        }
      />
    </div>
  );
}

export const invitationJWTSchema = z.object({
  sub: z.string(),
  invitationId: z.string(),
  auth: z.object({
    authToken: z.string()
  })
});

export type InvitationJWT = z.infer<typeof invitationJWTSchema>;

export function getInvitationDetails(
  query: URLSearchParams
):
  | { invitationDetails: InvitationJWT; raw: string; error: null }
  | { invitationDetails: null; raw: string | null; error: Error } {
  const authJwt = query.get('invitation');
  try {
    if (authJwt === null)
      throw new Error(
        `No invite request detected. 'invitation' query parameter is missing`
      );
    const decoded = jwtDecode(authJwt!);

    // TODO: Refactor to remove nested try-catch
    try {
      const validated = invitationJWTSchema.parse(decoded);
      return {
        invitationDetails: validated,
        raw: authJwt,
        error: null
      };
    } catch (validationError) {
      const resolvedError = resolveError(validationError);
      resolvedError.message = `Invitation token is invalid: ${resolvedError.message}`;
      throw resolvedError;
    }
  } catch (error) {
    return {
      invitationDetails: null,
      raw: authJwt,
      error: resolveError(error)
    };
  }
}
