import { PatientRoutes } from 'routes';
import { Role } from 'features/auth/types';
import {
  AssessmentIntro,
  PatientCgs,
  // PatientDemographics,
  // PatientDemographicsInjury,
  // PatientDemographicsPersonalHistory,
  // PatientDemographicsProfile,
  // PatientHowAreYouTracker,
  PatientOnboardingProfile,
  PatientSummary,
  PatientTreatmentRecommendations
} from 'features/patient';
import { QuestionnaireCompletionPromis } from 'features/patient/assessment/questionnaire';
import {
  PatientDhi,
  PatientPcs,
  PatientPromis
} from 'features/patient/outcome-measures';
// import { PatientSsoInfo } from 'features/patient/sso-info';
// import { SymptomSso } from 'features/symptoms';
import { PrescriptionPadExportContainer } from 'features/prescription-pad/prescription-pad';
import { PatientOutcomeProgress } from 'features/patient/outcome-measures/patient-outcome-progress';
import { PatientHomeV2 } from 'features/patient/patient-home/patient-home-v2';
import { RouteTypeDef } from './types';
import { QuestionnaireCompletionEnd } from 'features/patient/assessment/questionnaire/questionnaire-completion-end';
import { PrivateRouteProps } from 'components/routes/private-route';
export const PatientOnboardingRoutes: PrivateRouteProps[] = [
  {
    /**
     * WARN: This is not a test route
     */
    path: PatientRoutes.PatientOnboardingProfile,
    component: PatientOnboardingProfile,
    exact: true
  }
].map(route => ({
  ...route,
  roles: [Role.Patient]
}));
export const PatientTestRoutes: PrivateRouteProps[] = [
  {
    path: PatientRoutes.PatientCgs,
    component: PatientCgs
  },
  {
    path: PatientRoutes.PatientPromis,
    component: PatientPromis
  },
  {
    path: PatientRoutes.PatientDhi,
    component: PatientDhi
  },
  {
    path: PatientRoutes.PatientPcs,
    component: PatientPcs
  },
  {
    /**
     * Assessment introduction page
     * Disabled unless the user has 0 questionnaires completed
     * Otherwise this should be disabled
     */
    path: PatientRoutes.PatientOutcomeMeasures,
    component: AssessmentIntro
  },
  {
    /**
     * Assessment resume page.
     * Disabled unless the user has at least one questionnare completed
     */
    path: PatientRoutes.PatientOutcomeProgress,
    component: PatientOutcomeProgress,
    roles: [Role.Patient],
    noTestDataNeeded: true
  },
  {
    path: PatientRoutes.Completion,
    component: QuestionnaireCompletionEnd
  },
  {
    path: PatientRoutes.CompletionPromis,
    component: QuestionnaireCompletionPromis
  }
].map((route: Omit<PrivateRouteProps, 'roles'>) => ({
  ...route,
  roles: [Role.Patient]
}));

/**
 * All patient routes that are not test routes
 */
export const PatientOnlyRoutes: PrivateRouteProps[] = [].map(
  (route: RouteTypeDef) => ({ ...route, roles: [Role.Patient] })
);

export const PatientAndClinicianRoutes: PrivateRouteProps[] = [
  {
    path: PatientRoutes.PatientHome,
    component: PatientHomeV2
  },
  {
    path: PatientRoutes.PatientTreatmentRecommendationsExportPDF,
    component: PrescriptionPadExportContainer
  },
  {
    path: PatientRoutes.PatientTreatmentRecommendations,
    exact: true,
    component: PatientTreatmentRecommendations
  },
  // {
  //   path: PatientRoutes.PatientTreatmentRecommendationsV2,
  //   exact: true,
  //   component: TreatmentRecommendationsV2
  // },
  {
    exact: true,
    path: PatientRoutes.PatientDashboard,
    component: PatientSummary
  }
  // {
  //   exact: true,
  //   path: PatientRoutes.PatientDemographics,
  //   component: PatientDemographics
  // },
  // {
  //   exact: true,
  //   path: PatientRoutes.PatientSymptom,
  //   component: SymptomSso
  // },
  // {
  //   path: PatientRoutes.PatientDemographicsProfile,
  //   component: PatientDemographicsProfile
  // },

  // {
  //   path: PatientRoutes.PatientSsoInfo,
  //   component: PatientSsoInfo
  // },
  // {
  //   path: PatientRoutes.PatientHowAreYouTracker,
  //   component: PatientHowAreYouTracker
  // },
  // {
  //   path: PatientRoutes.PatientDemographicsInjury,
  //   component: PatientDemographicsInjury
  // },
  // {
  //   path: PatientRoutes.PatientDemographicsPersonalHistory,
  //   component: PatientDemographicsPersonalHistory
  // },
].map((route: Omit<PrivateRouteProps, 'roles'>) => ({
  ...route,
  roles: [Role.ClinicAdmin, Role.Clinician, Role.Patient]
}));

export const PatientRouteDefinitions: PrivateRouteProps[] = [
  ...PatientTestRoutes,
  ...PatientOnlyRoutes,
  ...PatientAndClinicianRoutes
];
