import { useSelector } from 'react-redux';
import { generatePath, Link, matchPath } from 'react-router-dom';

import { HomeOutlined } from '@ant-design/icons';
import { Menu, MenuItemProps } from 'antd';
import {
  ClinicRoutes,
  PatientRoutePrefix,
  PatientRoutes,
  Routes
} from 'routes';

import { RootState, useAppSelector } from 'app/rootReducer';
import { menuIconStyle } from './menu-icon-style';

import { PlusCircleOutlined } from '@ant-design/icons';
import { DarkButton } from 'components/dark-button';
import { PatientRoutePattern } from 'components/route-switch/route-switch-patient/patient-route-helpers';
import { ReactComponent as RxSidemenuKnowledgeCentre } from 'img/rx-sidemenu-knowledge-centre.svg';
import { ReactComponent as RxSidemenuSummaryLogo } from 'img/rx-sidemenu-summary.svg';
import { ReactComponent as RxSidemenuTreatmentLogo } from 'img/rx-sidemenu-treatment.svg';
import { Role } from 'features/auth/types';

export function ClinicianMenu() {
  const location = useAppSelector(state => state.router.location);
  const clinicianPatientMenu = useClinicianPatientMenuInfo();
  const menuHighlightedRoute = clinicianPatientMenu.isMenuEnabled
    ? [PatientRoutePrefix, clinicianPatientMenu.match?.params.action].join('/')
    : location.pathname;
  const user = useAppSelector(s => s.auth.user);
  if (!user) return <></>;
  return (
    <Menu theme="light" mode="inline" selectedKeys={[menuHighlightedRoute]}>
      {user.role === Role.ClinicAdmin && (
        <Menu.Item
          key={ClinicRoutes.ClinicUserManagement}
          icon={<PlusCircleOutlined />}
        >
          <Link to={ClinicRoutes.ClinicUserManagement}>User Management</Link>
        </Menu.Item>
      )}

      {!clinicianPatientMenu.isMenuEnabled ? (
        <Menu.Item key={Routes.ClinicianHome} icon={<HomeOutlined />}>
          <Link to={Routes.ClinicianHome}>Home</Link>
        </Menu.Item>
      ) : (
        <ClinicianPatientMenuItem
          key={PatientRoutePrefix + PatientRoutes.PatientHome}
          icon={<HomeOutlined />}
          patientRoute={PatientRoutes.PatientHome}
          text={'Home'}
        />
      )}

      <ClinicianPatientMenuItem
        key={PatientRoutePrefix + PatientRoutes.PatientDashboard}
        icon={<RxSidemenuSummaryLogo style={menuIconStyle} />}
        disabled={!clinicianPatientMenu.isMenuEnabled}
        patientRoute={PatientRoutes.PatientDashboard}
        text={'ConcussionRx Summary'}
      />

      <ClinicianPatientMenuItem
        key={PatientRoutePrefix + PatientRoutes.PatientTreatmentRecommendations}
        icon={<RxSidemenuTreatmentLogo style={menuIconStyle} />}
        patientRoute={PatientRoutes.PatientTreatmentRecommendations}
        text={'Recommended Treatment'}
        disabled={!clinicianPatientMenu.isMenuEnabled}
      />
      {/* <ClinicianPatientMenuItem
        key={
          PatientRoutePrefix + PatientRoutes.PatientTreatmentRecommendationsV2
        }
        icon={<RxSidemenuTreatmentLogo style={menuIconStyle} />}
        patientRoute={PatientRoutes.PatientTreatmentRecommendationsV2}
        text={'V2 Recommended Treatment'}
        disabled={!clinicianPatientMenu.isMenuEnabled}
      /> */}
      <Menu.Item
        key="knowledge center"
        icon={<RxSidemenuKnowledgeCentre style={menuIconStyle} />}
        disabled
      >
        Knowledge Centre
      </Menu.Item>
      {clinicianPatientMenu.isMenuEnabled && (
        <>
          <Menu.Divider />
          <div style={{ padding: '1rem' }}>
            <DarkButton style={{ width: '100%' }}>
              <Link to={Routes.ClinicianHome}>Select another patient</Link>
            </DarkButton>
          </div>
        </>
      )}
    </Menu>
  );
}

function useClinicianPatientMenuInfo() {
  const location = useAppSelector(state => state.router.location);
  const match = matchPath<{ patientId: string; action: string }>(
    location.pathname,
    {
      path: `${PatientRoutePattern}/:action` as const
    }
  );
  const isMenuEnabled = !!match;
  return { isMenuEnabled, match };
}

export function ClinicianPatientMenuItem(
  props: {
    patientRoute: PatientRoutes;
    text: string;
  } & MenuItemProps
) {
  const location = useSelector((state: RootState) => state.router.location);
  const match = matchPath<{ patientId: string }>(location.pathname, {
    path: PatientRoutePattern
  });
  const { patientRoute, text, ..._props } = props;
  const route = match?.params
    ? generatePath(PatientRoutePattern, match?.params) + patientRoute
    : patientRoute;

  return (
    <Menu.Item {..._props}>
      {match ? <Link to={route}>{text}</Link> : <>{text}</>}
    </Menu.Item>
  );
}
