import { Tag } from 'antd';
import clsx from 'clsx';
import { useState } from 'react';
import { ActionTagRenderProps } from './action-tag-component';
import { UserEditableDetailsModal } from './user-add-modal-component';
import { InData } from './user-list-component';

export function ActionTagEditDetails<T extends InData>(
  props: ActionTagRenderProps<T>
) {
  const [state, setState] = useState({
    modal: {
      isOpen: false
    }
  });
  return (
    <>
      <UserEditableDetailsModal
        onSubmit={data =>
          props.onClick({
            ...props.record,
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email
          })
        }
        submitText="Save details"
        actionProcessingDescription="Updating details. Please do not close or reload this window or tab."
        isOpen={state.modal.isOpen}
        setIsOpen={isOpen =>
          setState(s => ({ ...s, modal: { ...s.modal, isOpen } }))
        }
        initialValues={{
          firstName: props.record.firstName,
          lastName: props.record.lastName,
          email: props.record.email
        }}
      />
      <Tag
        {...props.tagStyles}
        className={clsx('clickable', props.disabled && 'disabled')}
        style={{ padding: '0.1rem' }}
        onClick={() => {
          setState(s => ({ ...s, modal: { ...s.modal, isOpen: true } }));
        }}
      >
        {props.name}
      </Tag>
    </>
  );
}
