import { createRepo } from 'api/firebase';
import {
  Collection,
  OnboardingStep,
  PatientOnboarding,
  PatientSubcollection
} from 'documents';
import { User } from './authSlice';
import { Role } from './types';

/**
 * Returns `true` if the patient is in the onboarding process. Otherwise `false`
 */
export async function userIsBeingOnboarded(user: Pick<User, 'uid' | 'role'>) {
  if ([Role.Clinician, Role.ClinicAdmin].includes(user.role)) {
    return false;
  }

  // Get the onboarding collection for the patient
  const onboardingRepo = createRepo<PatientOnboarding>([
    Collection.Patients,
    user.uid,
    PatientSubcollection.Onboardings
  ]);
  const onboarding = await onboardingRepo.first();

  return onboarding?.lastCompletedStep !== OnboardingStep.Profile;
}
