import { ArrowRightOutlined } from '@ant-design/icons';
import { Card, Flex, Form, FormInstance, Input, Space } from 'antd';
import { db } from 'api/firebase';
import { useAppSelector } from 'app/rootReducer';
import { Subtitle, Text, Title } from 'components/mvp-typography';
import { fetchPatientData } from 'components/patient-info/patientInfoSlice';
import { PatientRoutePattern } from 'components/route-switch/route-switch-patient/patient-route-helpers';
import { Collection } from 'documents/document';
import PeopleLookingAtBrain from 'img/people-looking-at-brain.png';
import { useRef, useState } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { getFirstName } from 'utils/string/normalise';
import { doesUserHaveAssessments } from 'utils/user-has-assessments';

export function ClinicianHome() {
  const user = useAppSelector(state => state.auth.user);
  const history = useHistory();
  const inputRef = useRef<FormInstance | null>(null);

  const [state_error, state_setError] = useState<string | null>(null);

  if (!user) return null;

  const firstName = getFirstName(user.displayName ?? '');
  async function onFinish(args: { patientEmail: string }) {
    try {
      state_setError(null);
      // Don't do anything if the input is empty
      if (args.patientEmail.length === 0) return;

      const result = await db
        .collection(Collection.Patients)
        .where('email', '==', args.patientEmail)
        .get();

      // Creates a list of ids
      const ids = result.docs.map(_ => _.id);
      if (ids.length === 0) {
        throw new Error(`No user with (${args.patientEmail}) found.`);
      }

      if (ids.length !== 1) {
        throw new Error(
          `Email (${args.patientEmail}) returned multiple users.`
        );
      }

      const patientData = await fetchPatientData(ids[0]);

      const userHasData = doesUserHaveAssessments({
        dhi: patientData.dhi,
        impact: patientData.impactTests,
        promis: patientData.promis
      });

      if (!userHasData) {
        throw new Error(
          `MISSING DATA. This user needs to complete at least one assessment.`
        );
      }

      history.push(generatePath(PatientRoutePattern, { patientId: ids[0] }));
    } catch (e) {
      console.log('Caught error', e);

      if (e instanceof Error) {
        state_setError(
          [
            e.message.trim(),
            `If you think this is an error please wait a few moments and try again, or contact ConcussionRX.`.trim()
          ].join(' ')
        );
        return;
      }

      state_setError(
        `An uknown error occured. Got: '${e}' (${JSON.stringify(e)})`
      );
    }
  }

  return (
    <Flex justify={'center'}>
      <Space
        direction="vertical"
        style={{ width: '90%', textAlign: 'center' }}
        size={40}
      >
        <div>
          <Title style={{ paddingBottom: '15px' }} color={'#230056'}>
            {"Let's get your patient better,"} {firstName}!
          </Title>
          <Subtitle
            style={{ fontWeight: 'bold', lineHeight: '30px' }}
            uppercase
          >
            Concussions are complex - we make them simple. <br></br>
            <br></br>
            Concussionrx brings the ‘gold standard’ of interdisciplinary
            expertise to your fingertips, diagnosing concussion subtypes and
            prescribing an interdisciplinary treatment plan with 99% proven
            accuracy, in less time than a single consultation.
          </Subtitle>
        </div>
        <Flex justify={'center'}>
          <Card style={{ maxWidth: 800 }}>
            <Text color={'#230056'}>
              Enter your patient email address to see their report:
            </Text>
            <Form onFinish={onFinish} ref={ref => (inputRef.current = ref)}>
              <Form.Item name="patientEmail">
                <Input
                  // ref={inputRef.current}
                  placeholder={`Patient's email`}
                  suffix={
                    <ArrowRightOutlined
                      onClick={() => inputRef.current!.submit()}
                    />
                  }
                />
              </Form.Item>
            </Form>

            {state_error && <Text color="red">{state_error}</Text>}
          </Card>
        </Flex>

        <img
          style={{
            width: 400,
            height: 300,
            top: 506,
            left: 643,
            borderRadius: 100
          }}
          src={PeopleLookingAtBrain}
        />

        <Text color={'#230056'}>
          If you want to learn more about how it works,{' '}
          <a
            style={{ color: 'inherit', textDecoration: 'underline' }}
            href={'https://concussionrx.com'}
          >
            click here
          </a>
        </Text>
      </Space>
    </Flex>
  );
}
