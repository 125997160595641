import './PatientOnboardingProfile.scss';

import { useState } from 'react';

import {
  Collection,
  OnboardingStep,
  PatientOnboarding,
  PatientProfile2,
  PatientSubcollection
} from 'documents';

import { useDocument } from 'hooks/firestore';

import { useAppSelector } from 'app/rootReducer';
import { OnboardingProgress, Page, ProfileForm2 } from 'components';
import { SignOutButton } from 'components/sign-out-button';

import { PatientRoutePrefix } from 'routes';

import { ConfigProvider } from 'antd';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import { validateProfileForm2 } from 'utils/validators';

export function PatientOnboardingProfile() {
  const [submitDisabled, setDisabled] = useState(true);
  const user = useAppSelector(state => state.auth.user);

  const dispatch = useDispatch();

  const { hasError, isLoading, doc, update } = useDocument<PatientProfile2>({
    path: Collection.PatientProfiles,
    id: user!.uid
  });

  const { update: updateOnboarding } = useDocument<PatientOnboarding>({
    path: `${Collection.Patients}/${user!.uid}/${
      PatientSubcollection.Onboardings
    }`
  });

  async function onDone() {
    console.log('[PatientOnboardingProfile] onDone invoked');
    await updateOnboarding({
      lastCompletedStep: OnboardingStep.Profile
    });

    dispatch(push(PatientRoutePrefix));
  }

  async function onUpdate(input: any) {
    await update(input);

    // it's validated on input of upcoming changes; not doc here maybe this can be placed in useEffect instead(?)
    const isInvalid = validateProfileForm2(input) ? false : true;
    setDisabled(isInvalid);
  }

  return (
    <Page
      isLoading={isLoading}
      hasError={hasError}
      className={'profile_container'}
    >
      {/**
       * Change the theme only for this page.
       * If the styles are used across the whole app then this should be moved to App.tsx
       *
       * https://ant.design/docs/react/customize-theme#nested-theme
       */}
      <ConfigProvider
        theme={{
          token: {
            /**
             * Design tokens of select components
             * https://ant.design/components/select#design-token
             */
            colorBgContainer: 'none',
            colorBorder: '#767679',
            colorText: '#767679'
          }
        }}
      >
        <SignOutButton rounded />
        <OnboardingProgress />
        <ProfileForm2
          submitDisabled={submitDisabled}
          /**
           * Initialise `impactLocation` to an empty array
           * WARN: This is needed because the validation code assumes `impactLocation` exists
           * but `doc` doesn't contain it
           **/
          value={{ impactLocation: [], ...doc }}
          onChange={onUpdate}
          onDone={onDone}
        />
      </ConfigProvider>
    </Page>
  );
}
